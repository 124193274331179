import React from "react";
import classNames from "classnames";
import t from "@utils/t";
import useLang from "@hooks/useLang";
import { Text, Image, Button } from "@atoms";

const CtaGrid = ({ ctas, last }) => {
  const lang = useLang();
  return (
    <section
      className={classNames("border-t-2 border-black", {
        "border-b-2": !last,
      })}
    >
      <div className="flex flex-wrap">
        {ctas?.map((cta, i) => {
          const { uid, icon, heading, descriptor, buttons } = cta;
          return (
            <div
              key={uid}
              className={classNames(
                "flex w-full justify-center lg:flex-[0_1_50%]",
                {
                  "bg-green": i % 3 === 0,
                  "bg-white": i % 3 === 1,
                  "bg-gray": i % 3 === 2,
                  "lg:justify-end": i % 2 === 0,
                  "lg:justify-start": i % 2 === 1,
                }
              )}
            >
              <div className="w-full max-w-xl">
                <div
                  className={classNames(
                    "flex h-full flex-col items-start justify-between px-4 text-left sm:px-6 md:px-8 md:py-20",
                    {
                      "lg:pl-28 lg:pr-20 xl:pl-16 xl:pr-20": i % 2 === 0,
                      "lg:pl-20 lg:pr-28 xl:pl-20 xl:pr-16": i % 2 === 1,
                      "py-12": !buttons?.length,
                      "pb-12 pt-12": buttons?.length,
                    }
                  )}
                >
                  <div className="flex flex-col gap-8">
                    <div
                      className={classNames(
                        "relative flex items-center gap-4",
                        {
                          "-left-0.5": icon,
                        }
                      )}
                    >
                      {icon && (
                        <div className="relative flex-shrink-0">
                          <Image image={icon} eager className="h-12 w-12" />
                        </div>
                      )}
                      <Text variant="lg" className="font-bold">
                        {heading}
                      </Text>
                    </div>
                    {descriptor && <Text variant="body">{descriptor}</Text>}
                    {!!buttons?.length && (
                      <div className="flex flex-wrap gap-8 md:gap-12">
                        {buttons.map(button => {
                          const { uid: linkUid, link } = button;
                          if (link?.url) {
                            return (
                              <div key={linkUid} className="text-left">
                                <Button
                                  to={link.url}
                                  className="mt-auto"
                                  color={i % 3 === 0 ? "black" : "green"}
                                  download={
                                    button.download || button.type === "asset"
                                  }
                                >
                                  {link.text || t("Learn More", lang)}
                                </Button>
                              </div>
                            );
                          }
                          return null;
                        })}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </section>
  );
};

CtaGrid.defaultProps = {};

export default CtaGrid;
